import axios from '@/libs/axios'

export default class Binnacles {

    /**
     * Function to binnacles type
     * @return Promise
     */
    getBinnaclesType(idEmpresa) {
        return axios.get(`/v2/Bitacoras/GetTiposBitacoras`);
    }

    getDetailBinnacle(idBitacora) {
        return axios.get(`/v2/Bitacoras/GetDetail/${idBitacora}`);
    }
}


