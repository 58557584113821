<template>
	<b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
		<b-card v-if="seller" text-variant="center" class="card card-congratulations">
			<!-- images -->
			<b-img :src="require('@/assets/images/elements/decore-left.png')" class="congratulations-img-left" />
			<b-img :src="require('@/assets/images/elements/decore-right.png')" class="congratulations-img-right" />
			<!--/ images -->

			<b-avatar variant="primary" size="70" class="shadow mb-2">
				<feather-icon size="28" icon="AwardIcon" />
			</b-avatar>
			<h1 class="mb-1 mt-50 text-white">
				Felicidades {{ seller.vendedor }}
			</h1>
			<b-card-text class="m-auto w-75">
				Has realizado <strong>{{ seller.porcentaje }}%</strong> más ventas del mes anterior.
			</b-card-text>
		</b-card>
	</b-overlay>
</template>

<script>
import moment from 'moment'
import {
	BCard, BImg, BAvatar, BCardText, BOverlay,
} from 'bootstrap-vue'

import DashboardsProvider from '@/providers/Dashboards'
const DashboardsResource = new DashboardsProvider()

export default {
	components: {
		BCard,
		BAvatar,
		BImg,
		BCardText,
		BOverlay,
	},
	props: {
        filterBy: {
            type: String,
            default: () => 'current_month',
            required: true,
        },
		refresh: {
            type: Boolean,
            default: () => false,
            required: true,
        },
    },
	data() {
        return {
            loading: false,
			seller: null,
		}
	},
	computed: {
        isLoadDashboard(){
            return this.findSetting('ACTIVAR_CARGA_DASHBOARD') || false
        }
    },
	async mounted() {
		if (this.isLoadDashboard) {
			await this.getTopSeller()
		}
	},
	methods: {
		async getTopSeller () {
			this.seller = null
			const query = {
				...this.filterByDates(this.filterBy)
			}
			if (this.filterBy && this.filterBy.key) {
				this.loading = true
				const { data } = await DashboardsResource.getTopSeller(query)
				this.loading = false
				this.seller = data.data
			}
		}
	},
	watch: {
		refresh(value) {
			if (value) {
				this.getTopSeller()
			}
		},
        filterBy(value) {
            this.getTopSeller()
        }
    }
}
</script>
