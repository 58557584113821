<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
        <b-card no-body>
            <b-card-header>
                <b-card-title>Ventas Con Descuentos</b-card-title>
                <b-row class="mt-1">
                    <b-col cols="12" md="3">
                        <b-form-group label="Fecha Inicio" label-for="date_start">
                            <flat-pickr v-model="filter.fechaIni" id="date_start" class="form-control flatpickr-small"
                                :config="{ dateFormat: 'Y-m-d' }" placeholder="DD/MM/YYYY" />
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" md="3">
                        <b-form-group label="Fecha Fin" label-for="date_start">
                            <flat-pickr v-model="filter.fechaFin" id="date_end" class="form-control flatpickr-small"
                                :config="{ dateFormat: 'Y-m-d' }" placeholder="DD/MM/YYYY" />
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" md="3" class="mt-2">
                        <b-button :disabled="loading" @click="getSalesDisccounts" size="sm" variant="primary" class="mr-1">
                            <feather-icon icon="SearchIcon" /> Buscar
                        </b-button>
                    </b-col>
                </b-row>
            </b-card-header>      

            <div ref="tableContainer" class="table-responsive b-table-sticky-header">
                <table class="table b-table table-sm b-table-no-border-collapse  b-table-selectable b-table-select-single w-100 text-small small">
                    <thead>
                        <tr>   
                            <th style="font-size: 10px!important;">Folio</th>                     
                            <th style="font-size: 10px!important;">Articulo</th>
                            <th style="font-size: 10px!important;">Clave</th>
                            <th style="font-size: 10px!important;">Um</th>
                            <th style="font-size: 10px!important;">Unidades</th>
                            <th style="font-size: 10px!important;">Precio</th>
                            <th style="font-size: 10px!important;">Desc</th>
                            <th style="font-size: 10px!important;">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in articles" :key="index">
                            <td style="font-size: 10px!important;">{{ item.folio }}</td>
                            <td style="font-size: 10px!important;">{{ item.articulo }}</td>
                            <td style="font-size: 10px!important;">{{ item.clave_Articulo }}</td>
                            <td style="font-size: 10px!important;">{{ item.unidad_Venta }}</td>
                            <td style="font-size: 10px!important;">{{ item.unidades }}</td>
                            <td style="font-size: 10px!important;">{{ item.precio_Unitario_Impto }}</td>
                            <td style="font-size: 10px!important;">%{{ item.pctje_Dscto }}</td>
                            <td style="font-size: 10px!important;">{{ formatCurrency(item.precio_Unitario_Impto - (item.precio_Unitario_Impto * (item.pctje_Dscto / 100))) }}</td>
                        </tr>
                    </tbody>
                    
                </table>
            </div>
        </b-card>
    </b-overlay>
</template>

<script>
import {
	BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
	BFormGroup, BFormInput, BFormSelect, BFormCheckbox, BFormRadio, BFormRadioGroup, BFormTextarea, BFormInvalidFeedback,
	BButton, BOverlay,
} from 'bootstrap-vue'

import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import * as helper from '@/libs/helpers';
import vSelect from "vue-select";
import { heightTransition } from '@core/mixins/ui/transition'
import moment from 'moment';

import { ref } from "@vue/composition-api";

import DashboardsProvider from '@/providers/Dashboards'
const DashboardsResource = new DashboardsProvider()

export default {
    name: 'SalesDisccounts',
	components: {
		BRow,
		BCol,
		BCard,
		BCardHeader,
		BCardTitle,
		BCardText,
		BCardBody,
		BMedia,
		BAvatar,
		BMediaAside,
		BMediaBody,
		BFormGroup, 
		BFormInput, 
		BFormSelect, 
		BFormCheckbox, 
		BFormRadio, 
		BFormRadioGroup, 
		BFormTextarea, 
		BFormInvalidFeedback,
		BButton,
        BOverlay,

		//
		flatPickr,
        vSelect,
	},
	mixins: [heightTransition],
    setup() {

        const loading = ref(false)
        const articles = ref([])
		const filter = ref({
			fechaIni: moment().startOf('month').format('YYYY-MM-DD'),
			fechaFin: moment().format('YYYY-MM-DD'),
			tipoBitacora: null,
		})

        const getSalesDisccounts = async () => {
            loading.value = true
            articles.value = []
            try {
                const { data } = await DashboardsResource.getDoctosPvDescuentos(filter.value)
                loading.value = false
                if (data.isSuccesful) {
                    articles.value = data.data
                } else {
                    helper.danger(data.message)
                }
            } catch (error) {
                loading.value = false
                helper.handleResponseErrors(error)
            }
        }
            
        return {
            loading,
            filter,
            articles,

            getSalesDisccounts,
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
@import '~@core/scss/vue/libs/vue-flatpicker.scss';

</style>

<!-- <style>
.b-table-sticky-header {
    overflow-y: auto;
    max-height: 300px;
}
</style> -->