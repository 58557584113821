<template>
	<b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
		<b-card no-body>
			<b-card-header>
				<h4>Venta Diaria Vs Año Anterior</h4>
				<b-button @click="changeView" size="sm" variant="outline-primary" class="_btn-icon">
					<feather-icon size="18" :icon="isShowChart ? 'EyeIcon' : 'EyeOffIcon'" />
					<span v-text="isShowChart ? ' Ver detalles' : ' Ver gráfica'" />
				</b-button>
			</b-card-header>

			<b-card-body class="pb-0">
				<vue-apex-charts v-if="isShowChart && !loading && sales.length > 0" ref="chartBar" type="line" :options="chartOptions" :series="series" />

				<div v-if="!isShowChart" ref="tableContainer" class="table-responsive b-table-sticky-header">
					<table 
						class="table table b-table table-sm b-table-no-border-collapse b-table-selectable b-table-select-single w-100 text-small small">
						<thead>			
							<tr>
								<th colspan="3" class="text-center">{{ monthFilter }}</th>
							</tr>				
							<tr>
								<th>Día</th>								
								<th>Importe {{ lastYear }}</th>
								<th>Importe {{ currentYear }}</th>
							</tr>
						</thead>
						<tbody v-for="(item, index) in daysSales" :key="index">
							<tr>
								<td>{{ item.dia }}</td>
								<td>{{ formatCurrency(item.ventaActual) }}</td>
								<td>{{ formatCurrency(item.ventaAnterior) }}</td>
							</tr>
						</tbody>
						<tfoot>
							<td class="font-weight-bold">TOTAL</td>							
							<td class="font-weight-bold">{{ formatCurrency(lastYearSales.reduce((carry, item) => carry + item.venta_Importe, 0)) }}</td>
							<td class="font-weight-bold">{{ formatCurrency(currentYearSales.reduce((carry, item) => carry + item.venta_Importe, 0)) }}</td>
						</tfoot>
					</table>
				</div>
			</b-card-body>
		</b-card>
	</b-overlay>
</template>

<script>
import {
	BCard, BCardHeader, BCardTitle, BCardBody, BCardText, BButton, BOverlay,
} from 'bootstrap-vue'
import moment from 'moment'
import { kFormatter } from '@core/utils/filter'
import FeatherIcon from '../feather-icon/FeatherIcon.vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

import DashboardsProvider from '@/providers/Dashboards'
const DashboardsResource = new DashboardsProvider()


export default {
	components: {
		VueApexCharts,
		BCardText,
		BCard,
		BCardHeader,
		BCardTitle,
		BCardBody,
		BButton,
		BOverlay,
	},
	props: {
		filterBy: {
			type: Object,
			required: true,
		},
		refresh: {
			type: Boolean,
			default: () => false,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
			sales: [],            
			chartOptions: {
				chart: {
					type: 'line',
					toolbar: { show: false },
					zoom: { enabled: false },
					zoom: {
						enabled: false
					},
					// Hacer el gráfico fluido y responsivo
					width: '100%',
					height: 'auto',
					dropShadow: {
						enabled: true,
						top: 2,
						left: 2,
						blur: 4,
						opacity: 0.2,
					},
					padding: {
						top: 20,
						right: 20,
						bottom: 20,
						left: 20,
					},
					animations: {
						enabled: true,
						easing: 'easeinout',
						speed: 800, // La duración de la animación
					}
				},
				xaxis: {
					title: {
						text: 'Ventas (Días)'
					},
					labels: {
						rotate: -45, // Rotar etiquetas para evitar superposición
						style: {
							fontSize: '12px', // Ajustar el tamaño de la fuente
						},
					},
					axisTicks: {
						show: true,
					},
					categories: [],
					axisBorder: {
						show: true,
					},
					tickPlacement: 'on',
				},
				yaxis: {
					tickAmount: 0,
					title: {
						text: 'Ventas (Importes)'
					},
					labels: {
						formatter(val) {
							if (val != undefined && val > 0) {
								return kFormatter(val)
							} else {
								return 0
							}
						},
					},
				},
				markers: {
					size: 5,
					colors: [$themeColors.primary, $themeColors.warning],
					strokeColors: '#fff',
					strokeWidth: 2,
					hover: {
						size: 7
					}
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					curve: 'smooth', // Líneas más suaves
					width: 2, // Ajustar el grosor de las líneas
				},
				colors: [$themeColors.primary, $themeColors.warning],
				responsive: [
					{
						breakpoint: 1000, // Ajustes para pantallas menores a 1000px
						options: {
							chart: {
								width: '100%',
							},
							legend: {
								position: 'bottom', // Cambiar la posición de la leyenda para pantallas pequeñas
							},
						},
					},
					{
						breakpoint: 600, // Ajustes para pantallas menores a 600px
						options: {
							chart: {
								width: '100%',
							},
							xaxis: {
								labels: {
									show: false, // Ocultar etiquetas para evitar sobrecarga visual
								},
							},
							yaxis: {
								labels: {
									show: false, // Ocultar etiquetas en pantallas pequeñas
								},
							},
						},
					},
				],
				legend: {
					show: true,
					position: 'top',
					fontFamily: 'Montserrat',
					// fontSize: '12px',
					labels: {
						colors: '#b9b9c3'
					},
					markers: {
						width: 19,
						height: 19,
						strokeWidth: 0,
						strokeColor: '#fff',
						fillColors: undefined,
						radius: 12,
						offsetX: 0,
						offsetY: 0
					},
					itemMargin: {
						horizontal: 10,
						vertical: 0
					},

				},
				tooltip: {
					// Personaliza el contenido del tooltip
					enabled: true, // habilita el tooltip
					shared: true, // si hay múltiples series, puedes usar esto para compartir el tooltip
					followCursor: true, // seguir el cursor del ratón
					intersect: false,
					style: {
						fontSize: '12px',  // Tamaño de fuente
						background: '#fff', // Color de fondo del tooltip
					},
					x: {
						show: true, // Ocultar el eje x
						formatter: (value) => {
							// Personaliza la salida según el índice de la serie
							return `Día ${value}`; // Aquí puedes personalizar el formato
						},
					},
					y: {
						formatter: (value, { seriesIndex, series }) => {
							if (value != undefined && value > 0) {
								return `${kFormatter(value)}`; // Aquí puedes personalizar el formato
							} else {
								return 0
							}
						},
					},
				},

			},
			series: [],

			isShowChart: true,
			currentMonth: '',
			lastMonth: '',
			firstMonth: 0,
			secondMonth: 0,
			currentYear: 0,
			lastYear: 0,
			currentYearSales: [],
			lastYearSales: [],
			itemsMerges: [],
			daysSales: [],
		}
	},
	computed: {
		monthFilter() {
			const _date = this.filterByDates(this.filterBy.key)
			return moment(_date.fechaIni).format('MMMM')
		},
		isLoadDashboard() {
			return this.findSetting('ACTIVAR_CARGA_DASHBOARD') || false
		}
	},
	async mounted() {
		if (this.isLoadDashboard) {
			await this.getSalesByDayVsPreviousDay()
		}
	},
	methods: {
		kFormatter,
		changeView() {
			this.isShowChart = !this.isShowChart

			// if (this.isShowChart) {
			// 	// Asegurar que el gráfico se redimensiona al volver a ser visible
			// 	this.$nextTick(() => {
			// 		this.updateChartData()
			// 	});
			// }
		},
		updateChartData() {
			this.$nextTick(() => {

				// this.chartOptions.labels = []
				// this.series = []

				// this.chartOptions.labels = this.sales.map(item => item.zona).flat();
				// this.series = this.sales.map(item => item.venta_Importe).flat();

				//Forzar la actualización del gráfico
				// if (this.sales.length > 0 && this.series.length > 0) {
				// this.$refs.donutChart.updateSeries(this.series, true);
				// this.$refs.chartLine.updateOptions(this.chartOptions, true, true, true);
				// }
			})
		},
		async getSalesByDayVsPreviousDay() {
			this.daysSales = []
			this.sales = []
			this.series = []
			this.currentMonth = ''
			this.lastMonth = ''
			this.firstMonth = 0
			this.secondMonth = 0
			this.currentYear = 0
			this.lastYear = 0
			this.chartOptions.xaxis.categories = []

			const query = {
				...this.filterByDates(this.filterBy.key),
				vendedorId: this.filterBy.vendedor_Id
			}

			this.loading = true
			const { data } = await DashboardsResource.getSalesByDayVsPreviousDay(query)
			this.loading = false
			if (data.isSuccesful && data.data != null && data.data.length > 0) {

				//Obtener las ventas del año actual y del año anterior tomando en cuenta la fecha de this.filterBy.key
				this.sales = data.data
				this.currentYear = moment(query.fechaIni).year()
				this.lastYear = this.currentYear - 1

				this.currentYearSales = this.sales.filter(item => item.anio_Dummy == this.currentYear)
				this.lastYearSales = this.sales.filter(item => item.anio_Dummy == this.lastYear)
				const daysInMonth = moment(query.fechaFin, "YYYY-MM").daysInMonth();

				let allDays = []
				for (let index = 1; index <= daysInMonth; index++) {
					allDays.push(index)					
					if (this.currentYearSales.length > 0) {
						const finded = this.currentYearSales.find(i => i.dia == index)
						if (!finded) {
							const firstItem = this.currentYearSales[0]
							this.currentYearSales.push({
								anio_Dummy: this.currentYear,
								dia_Dummy: index,
								dia: index.toString(),
								mes_Dummy: firstItem.mes_Dummy,
								mes: firstItem.mes,
								venta_Importe: 0,
								porcentaje: 0
							})
						}
					}

					if (this.lastYearSales.length > 0) {
						const finded2 = this.lastYearSales.find(i => i.dia == index)
						if (!finded2) {
							const firstItem = this.lastYearSales[0]
							this.lastYearSales.push({
								anio_Dummy: this.lastYear,
								dia_Dummy: index,
								dia: index.toString(),
								mes_Dummy: firstItem.mes_Dummy,
								mes: firstItem.mes,
								venta_Importe: 0,
								porcentaje: 0
							})
						}
					}
				}

				this.currentYearSales = this.currentYearSales.sort((a, b) => a.dia_Dummy - b.dia_Dummy)
				this.lastYearSales = this.lastYearSales.sort((a, b) => a.dia_Dummy - b.dia_Dummy)

				//Obtener los días del mes
				const days = allDays //[...new Set([...currentYearSales, ...lastYearSales].map(i => i.dia_Dummy))]

				//Obtener las ventas del año actual y del año anterior
				const currentYearSalesByDay = this.currentYearSales.map(i => i.venta_Importe).flat()
				const lastYearSalesByDay = this.lastYearSales.map(i => i.venta_Importe).flat()
				this.chartOptions.xaxis.categories = days
				this.series = [
					{
						name: `Ventas ${moment(query.fechaIni).format('MMMM')} ${this.currentYear}`,
						data: currentYearSalesByDay
					},
					{
						name: `Ventas ${moment(query.fechaIni).add(-1, 'year').format('MMMM')} ${this.lastYear}`,
						data: lastYearSalesByDay
					}
				]

				this.itemsMerges = [...new Set([...this.currentYearSales, ...this.lastYearSales])]

				const salesByDay = {};
				this.itemsMerges.forEach(item => {
					
					const dayKey = item.dia_Dummy; // Usa el día como clave
					if (!salesByDay[dayKey]) {
						salesByDay[dayKey] = {
							dia: item.dia_Dummy,
							ventaActual: 0,
							ventaAnterior: 0,
						};
					}

					// Asignar los valores dependiendo del año
					if (item.anio_Dummy === this.lastYear) {
						salesByDay[dayKey].ventaActual = item.venta_Importe;
					} else if (item.anio_Dummy === this.currentYear) {
						salesByDay[dayKey].ventaAnterior = item.venta_Importe;
					}
				});

				// Convertir el objeto a un array ordenado por día
				this.daysSales = Object.values(salesByDay).sort((a, b) => a.dia - b.dia);
			}
		},
	},
	watch: {
		refresh(value) {
			if (value) {
				this.getSalesByDayVsPreviousDay()
			}
		},
		filterBy(value) {
			this.getSalesByDayVsPreviousDay()
		}
	}

}
</script>
