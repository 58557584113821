<template>
	<b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
		<b-card no-body>
			<b-card-header>
				<h4>Ventas Por Vendedor</h4>
				<b-button @click="changeView" size="sm" variant="outline-primary" class="_btn-icon">
					<feather-icon size="18" :icon="isShowChart ? 'EyeIcon' : 'EyeOffIcon'" />
					<span v-text="isShowChart ? ' Ver detalles' : ' Ver gráfica'" />
				</b-button>
			</b-card-header>

			<b-card-body class="pb-0">
				<vue-apex-charts v-if="isShowChart && !loading && sales.length > 0" ref="chartBar" type="bar" height="350"  :options="chartOptions" :series="series" />

				<div v-if="!isShowChart" ref="tableContainer" class="table-responsive b-table-sticky-header">
					<table
						class="table table b-table table-sm b-table-no-border-collapse b-table-selectable b-table-select-single w-100 text-small small">
						<thead>							
							<tr>
								<th>Vendedor</th>
								<th>Importe</th>
							</tr>
						</thead>
						<tbody>							
							<tr v-for="(item, index) in sales" :key="index">
								<td>{{ item.vendedor }}</td>
								<td>
									{{ formatCurrency(item.venta_Importe) }}
								</td>
							</tr>
						</tbody>
						<tfoot>
							<tr>
								<td class="font-weight-bold text-right">TOTAL</td>
								<td class="font-weight-bold">
									{{ formatCurrency(sales.reduce((carry, item) => carry + item.venta_Importe, 0)) }}
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</b-card-body>
		</b-card>
	</b-overlay>
</template>

<script>
import {
	BCard, BCardHeader, BCardTitle, BCardBody, BCardText, BButton, BOverlay,
} from 'bootstrap-vue'
import moment from 'moment'
import { kFormatter } from '@core/utils/filter'
import FeatherIcon from '../feather-icon/FeatherIcon.vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

import DashboardsProvider from '@/providers/Dashboards'
const DashboardsResource = new DashboardsProvider()


export default {
	components: {
		VueApexCharts,
		BCardText,
		BCard,
		BCardHeader,
		BCardTitle,
		BCardBody,
		BButton,
		BOverlay,
	},
	props: {
		filterBy: {
			type: Object,
			required: true,
		},
		refresh: {
			type: Boolean,
			default: () => false,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
			sales: [],
			series: [],
			chartOptions: {
				chart: {
					type: 'bar',
					toolbar: { show: false },
					zoom: { enabled: false },
					height: 350,
					animations: {
						enabled: true,
						easing: 'easeinout',
						speed: 800,
					}
				},
				plotOptions: {
					bar: {
						horizontal: false,
						columnWidth: '55%',
						endingShape: 'rounded',
						distributed: false,
						colors: {
							backgroundBarColors: ['#f2f2f2'],
							backgroundBarOpacity: 0.2,
						},
						states: {
							hover: {
								filter: {
									type: 'darken',
									value: 0.75,
								}
							}
						}
					}
				},
				dataLabels: { enabled: false },
				stroke: {
					show: true,
					width: 2,
					colors: ['transparent']
				},
				xaxis: {
					categories: [],
					labels: {
						rotate: -45,
						style: {
							fontSize: '12px',
							fontFamily: 'Montserrat'
						}
					}
				},
				yaxis: {
					title: {
						text: 'Ventas Importes',
						style: {
							fontSize: '14px',
							fontFamily: 'Montserrat'
						}
					},
					labels: {
						formatter: function (val) {
							return kFormatter(val)
						},
						style: {
							fontSize: '12px',
							fontFamily: 'Montserrat',
						}
					}
				},
				fill: {
					opacity: 1,
					// type: 'gradient',
					// gradient: {
					// 	shade: 'light',
					// 	type: "vertical",
					// 	shadeIntensity: 0.5,
					// 	opacityFrom: 0.85,
					// 	opacityTo: 0.55,
					// 	stops: [0, 90, 100]
					// }
				},
				tooltip: {
					// theme: 'dark',
					// y: {
					// 	formatter: function (val) {
					// 		return `${kFormatter(val)} ventas`;
					// 	}
					// }
				},				
			},


			isShowChart: true,
			currentMonth: '',
			lastMonth: '',
			firstMonth: 0,
			secondMonth: 0,
		}
	},
	computed: {
		isLoadDashboard() {
			return this.findSetting('ACTIVAR_CARGA_DASHBOARD') || false
		}
	},
	async mounted() {
		if (this.isLoadDashboard) {
			await this.getSalesAllSellers()
		}
	},
	methods: {
		kFormatter,
		changeView() {
			this.isShowChart = !this.isShowChart

			// if (this.isShowChart) {
			// 	// Asegurar que el gráfico se redimensiona al volver a ser visible
			// 	this.$nextTick(() => {
			// 		this.updateChartData()
			// 	});
			// }
		},
		updateChartData() {
			this.$nextTick(() => {

				// this.chartOptions.labels = []
				// this.series = []

				// this.chartOptions.labels = this.sales.map(item => item.zona).flat();
				// this.series = this.sales.map(item => item.venta_Importe).flat();

				//Forzar la actualización del gráfico
				// if (this.sales.length > 0 && this.series.length > 0) {
				// this.$refs.donutChart.updateSeries(this.series, true);
				// this.$refs.chartLine.updateOptions(this.chartOptions, true, true, true);
				// }
			})
		},
		async getSalesAllSellers() {
			this.sales = []
			this.series = []
			this.chartOptions.xaxis.categories = []

			const query = {
				...this.filterByDates(this.filterBy.key),
			}

			this.loading = true
			const { data } = await DashboardsResource.getSalesAllSellers(query)
			this.loading = false
			if (data.isSuccesful && data.data != null && data.data.length > 0) {
				this.sales = data.data
				this.series = [
					{
						name: 'Ventas',
						data: data.data.map(i => i.venta_Importe).flat()
					}
				]
				this.chartOptions.xaxis.categories = data.data.map(i => i.vendedor).flat();
			}
		},
	},
	watch: {
		refresh(value) {
			if (value) {
				this.getSalesAllSellers()
			}
		},
		filterBy(value) {
			this.getSalesAllSellers()
		}
	}

}
</script>
