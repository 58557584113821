<template>
  <div>
    <video
      ref="video"
      :width="videoWidth"
      :height="videoHeight"
      style="border: 1px solid black;"
    ></video>
  </div>
</template>

<script>
import { BrowserMultiFormatReader } from '@zxing/library';

export default {
  props: {
    onScanComplete: {
      type: Function,
      required: false,
      default: () => {}
    },
    cameraFacingMode: {
      type: String,
      default: 'environment',
    },
    videoWidth: {
      type: Number,
      default: 400,
    },
    videoHeight: {
      type: Number,
      default: 300,
    }
  },
  data() {
    return {
      codeReader: null,
      scannedResult: null,
      mediaStream: null,
    };
  },
  mounted() {
    this.initScanner();
  },
  methods: {
    initScanner() {
      this.codeReader = new BrowserMultiFormatReader();
      const videoElement = this.$refs.video;

      navigator.mediaDevices.getUserMedia({
        video: { facingMode: this.cameraFacingMode }
      })
      .then((stream) => {
        this.mediaStream = stream;  
        videoElement.srcObject = stream;
        videoElement.setAttribute("playsinline", true);
        videoElement.play();
        this.startScanning(videoElement);
      })
      .catch(err => {
        console.error("Error accediendo a la cámara: ", err);
        alert("Error accediendo a la cámara: " + err.message);
      });
    },
    startScanning(videoElement) {
      this.codeReader.decodeFromVideoElement(videoElement, (result, err) => {
        if (result) {
          this.scannedResult = result.text;
          this.onScanComplete(result.text); // Emitir el resultado escaneado
        }
        if (err) {
          if (!(err instanceof ZXing.NotFoundException)) {
            console.error(err);
            alert("Error escaneando código: " + err.message);
          }
        }
      });
    },
    stopScanner() {
      if (this.mediaStream) {
        this.mediaStream.getTracks().forEach(track => track.stop());
      }
    }
  },
  beforeDestroy() {
    this.stopScanner(); 
    if (this.codeReader) {
      this.codeReader.reset(); 
    }
  }
};
</script>

<style scoped>
video {
  width: 100%; /* Se adapta al contenedor */
  height: auto; /* Mantiene la relación de aspecto */
}
</style>
